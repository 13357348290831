












import Vue from 'vue';
import { PREFERS_DARK_COLOR_SCHEME } from './constants/constants';

export default Vue.extend({
    data() {
        return {
            isMounted: false,
            darkMediaQuery: window.matchMedia(PREFERS_DARK_COLOR_SCHEME),
        };
    },
    mounted() {
        const darkMediaQuery = window.matchMedia(PREFERS_DARK_COLOR_SCHEME);
        this.$vuetify.theme.dark = darkMediaQuery.matches;
        darkMediaQuery.addListener(this.toggleDarkTheme);

        this.$nextTick(function () {
            this.isMounted = true;
        });
    },
    destroyed() {
        this.darkMediaQuery.removeListener(this.toggleDarkTheme);
    },
    methods: {
        toggleDarkTheme(e: MediaQueryListEvent) {
            this.$vuetify.theme.dark = e.matches;
        },
    },
});
