<template>
    <div class="main">
        <div class="intro">
            <a
                href="https://themoonapes.bandcamp.com/album/six-of-seven"
                target="_blank"
            >
                Latest EP 'Six Of Seven' out now!<br />Get it here:
            </a>
        </div>

        <div class="main-img-container">
            <a
                href="https://themoonapes.bandcamp.com/album/six-of-seven"
                target="_blank"
            >
                <picture>
                    <source
                        srcset="
                            ../assets/the_moon_apes_six_of_seven_1000x1000_webp_convert.webp
                        "
                        type="image/webp"
                    />
                    <img
                        class="main-img"
                        src="../assets/the_moon_apes_six_of_seven_1000x1000.jpg"
                        alt="Six Of Seven album cover"
                    />
                </picture>
            </a>
        </div>

        <div class="main-text"></div>
    </div>
</template>

<script>
export default {
    name: 'Main',
};
</script>

<style lang="scss" scoped>
* {
    flex: 1;
}
.main {
    display: block;
    width: 100%;
}

.main-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
}

.main-img {
    max-width: 100%;
}

.intro {
    margin: 48px auto;
    text-align: center;
    white-space: pre-line;
    font-size: 12px;
    line-height: 2;

    a {
        text-decoration: none;
        color: inherit;
    }
}

.main-text {
    margin: 48px auto;
}
</style>
