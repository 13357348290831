import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    // {
    //     path: '/about',
    //     name: 'about'
    //     //     // component: Test
    //     //     // route level code-splitting
    //     //     // this generates a separate chunk (about.[hash].js) for this route
    //     //     // which is lazy-loaded when the route is visited.
    //     // component: () =>
    //     //     import(/* webpackChunkName: "Test" */ '../views/Test.vue')
    // },
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

export default router;
