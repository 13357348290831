<template>
    <div class="footer">
        <v-divider class="divider"></v-divider>
        <div class="links">
            <v-btn
                v-for="link in links"
                :key="link.icon"
                :href="link.url"
                class="link-button"
                target="_blank"
                dark
                depressed
                fab
                x-small
            >
                <v-icon size="16px">{{ link.icon }}</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: 'Footer',
    data: function () {
        return {
            links: [
                {
                    icon: 'mdi-soundcloud',
                    url: 'https://soundcloud.com/the-moon-apes',
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.footer {
    display: block;
    margin-bottom: 1.5rem;

    .divider {
        margin: 16px;
    }
    .links {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .link-button {
            margin-right: 8px;
        }
    }
}
</style>
