<template>
    <div class="header">
        <img
            class="img"
            src="../assets/the_moon_apes_1.png"
            alt="The Moon Apes"
        />
    </div>
</template>

<script>
export default {
    name: 'Header',
};
</script>

<style lang="scss" scoped>
.header {
    display: block;
    margin: 48px auto 0 auto;
}

.img {
    max-width: 100%;

    @media (prefers-color-scheme: dark) {
        filter: invert(0.928);
    }
}
</style>
