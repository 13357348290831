<template>
    <div class="home">
        <Header />
        <Main />
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/Header.vue';
import Main from '@/components/Main.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: 'home',
    components: {
        Header,
        Main,
        Footer,
    },
};
</script>

<style lang="scss" scoped>
* {
    // background: red !important;
}
.home {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50vw;
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .home {
        width: 60vw;
    }
}

@media (max-width: 768px) {
    .home {
        width: 80vw;
    }
}

@media (max-width: 550px) {
    .home {
        width: 100vw;
    }
}
</style>
